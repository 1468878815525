import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CommandChannelResponse, ZoomService } from '../services/zoom.service';
import { ChangeDetectorRef } from '@angular/core';
import { VideoQuality, ParticipantPropertiesPayload, Participant } from '@zoom/videosdk';
import { ButtonModule } from 'primeng/button';
import { ZoomClientType } from '../services/zoom.service';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmationService, MessageService, Message } from 'primeng/api';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AuthService } from '../services/auth.service';
import { CommandEvent, CommandEventType } from '../services/zoom.service';
import { SessionService } from '../services/session.service';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-coordinator',
  templateUrl: './coordinator.component.html',
  styleUrls: ['./coordinator.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ButtonModule, DropdownModule, ConfirmDialogModule],
})
export class CoordinatorComponent implements OnInit, OnDestroy {
  @ViewChild('inspectorVideo', { static: true }) inspectorVideo!: ElementRef<HTMLCanvasElement>;
  @ViewChild('coordinatorVideo', { static: true }) coordinatorVideo!: ElementRef<HTMLVideoElement>;
  // @ViewChild('videoPlayerContainer', { static: true }) videoPlayerContainer!: ElementRef<HTMLDivElement>;
  sessionId: string = "";
  zoomClient: ZoomClientType | void | null = null;
  videoQuality = VideoQuality.Video_1080P;
  sessionEnded = false;
  cloudRecording: any = null;
  isRecording = false;
  isPaused = false;
  micDevices: any[] = [];
  speakerDevices: any[] = [];
  inspectorCameraList: any[] | null = null;
  selectedMic: any;
  selectedSpeaker: any;
  isSessionStarted = false;
  isInspectorConnected = false;
  isMediaNetworkConnected = false;
  isSessionStarting = false;
  selectedInspectorCamera: any;

  constructor(
    private route: ActivatedRoute,
    private zoomService: ZoomService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private authService: AuthService,
    private sessionService: SessionService,
    private notify: NotificationService
  ) {}

  test() {
    console.log('test');
    this.getCameraList();
  }

  async ngOnInit(): Promise<void> {
    this.sessionId = this.route.snapshot.paramMap.get('sessionId') || '';
    this.notify.showToast('info', 'GoVideo', 'Connecting to media network...');
    if (this.sessionId) {
      const authorized = this.authService.authorize(this.sessionId);
      if (authorized && typeof authorized.role === 'number' && authorized.userIdentity && authorized.sessionId) {
        this.zoomClient = await this.zoomService.connect(authorized.sessionId, authorized.userIdentity, authorized.role);
        if (!this.zoomClient) {
          this.notify.showToast('error', 'GoVideo', 'Failed to connect to media network.', 0);
          this.router.navigate(['/contact-us']);
        } else {
          this.isMediaNetworkConnected = true;
          this.setupEventListeners();
          this.notify.showToast('success', 'GoVideo', 'Connected to media network.');
        }
      } else {
        this.notify.showToast('error', 'GoVideo', 'Not authorized to access this session.', 0);
        this.router.navigate(['/contact-us']);
      }
    } else {
      this.router.navigate(['/contact-us']);
    }
  }

  ngOnDestroy(): void {
    if (!this.sessionEnded) {
      this.zoomService.leave();
      this.zoomService.destroyClient();
    }
  }

  // async initZoom() {
  //   const userName = 'Coordinator';
  //   this.cdr.detach();
  //   this.zoomClient = await this.zoomService.connect(this.sessionId, userName, 1);
  //   if (!this.zoomClient) {
  //     throw new Error('Failed to initialize Zoom client');
  //   }
  //   this.setupEventListeners();
  //   this.startInspectorVideo();      
  //   this.cdr.reattach();
  //   this.cdr.detectChanges();
  // }

  setupEventListeners() {
    if (!this.zoomClient) {
      throw new Error('Zoom client not initialized');
    }
    this.zoomService.on('peer-video-state-change', (payload: { action: "Start" | "Stop"; userId: number }) => {
      console.log('Peer video state change');
      const participant = this.zoomService?.client?.getUser(payload.userId);
      console.log("Participant:", participant);
      if (payload.action === 'Start') {
        console.log('Peer state change event - Start video');
        if (participant?.userIdentity === "inspector") {
          this.cdr.detach();
          this.startInspectorVideo(participant);
          this.cdr.reattach();
          this.cdr.detectChanges();
        }
      } else {
        console.log('Peer state change event - Stop video');
        if (participant?.userIdentity === "inspector") {
            this.notify.showToast('info', 'GoVideo', 'Inspector has stopped the video');
        }
      }
    });

    this.zoomService.on('user-added', (payload: ParticipantPropertiesPayload[]) => {
      if (payload.length && payload[0].userIdentity === 'inspector') {
        this.isInspectorConnected = true;
        this.notify.showToast('info', 'GoVideo', 'INSPECTOR has joined the session');
        console.log('Participant joined event :', payload);
      }
    });

    this.zoomService.on('user-removed', (payload: ParticipantPropertiesPayload[]) => {
      if (payload.length && payload[0].userIdentity === 'inspector') {
        this.isInspectorConnected = false;
        this.notify.showToast('info', 'GoVideo', 'INSPECTOR has left the session');
        console.log('Participant left event:', payload);
      }
    });
  }

  async startSession(event: any): Promise<void> {
    try {
      if (!this.zoomClient) {
        throw new Error('Zoom client not initialized');
      }
      this.isSessionStarting = true;
      const stream = this.zoomClient.getMediaStream();
      if (!stream) {
        throw new Error('Media stream not found');
      }

      const coordinatorVideo = this.coordinatorVideo.nativeElement;

      if (!coordinatorVideo) {
        throw new Error('Video elements not found');
      }
      this.cdr.detach();
      await stream.startAudio();
      await stream.startVideo({
        videoElement: coordinatorVideo,
      })
      //update device lists
      this.micDevices = await this.zoomService.getMicList();
      this.selectedMic = this.micDevices[0];
      this.speakerDevices = await this.zoomService.getSpeakerList();
      this.selectedSpeaker = this.speakerDevices[0];
      this.isSessionStarted = true;
      if (!this.isInspectorConnected) {
        this.notify.showToast('warn', 'GoVideo', 'Waiting for the inspector to join the session');
      }

      // await stream.renderVideo(coordinatorVideo, this.zoomClient.getCurrentUserInfo().userId, 150, 150, 0, 0, this.videoQuality);  
      // setTimeout(() => {
      //   if (!this.isInspectorConnected) {
      //     this.messageService.add({ severity:'error', summary:'GoVideo', detail:'Inspector is unable to join the session' });
      //   } else {
      //     this.messageService.add({ severity:'success', summary:'GoVideo', detail:'Inspector has joined the session' });
      //   }
      // }, 30000);
      // console.log('Coordinator video and audio started');
      // await stream.renderVideo( coordinatorVideo, this.zoomClient.getCurrentUserInfo().userId, 150, 150, 0, 0, this.videoQuality);  
      // console.log('Coordinator video and audio started');
    } catch (error: any) {
      if (error.type !== 'INVALID_OPERATION' || error.reason !== 'Video is started') {
        console.error('Error starting coordinator video and audio', error);
      } else {
        console.log('Coordinator video is already started');
      }
    } finally {
      this.isSessionStarting = false;
      this.cdr.reattach();
      this.cdr.detectChanges();
    }
  }

  record(event: any): void {
    try {
      if (!this.cloudRecording) {
      this.cloudRecording = this.zoomClient?.getRecordingClient();
      if (!this.cloudRecording) {
        throw new Error('Failed to initialize recording client');
      }
      }
      if (!this.isRecording) {
        this.cloudRecording.startCloudRecording();
        this.notify.showToast('success', 'GoVideo', 'Recording started');
        this.isRecording = true;
      } else {
        this.cloudRecording.stopCloudRecording();
        this.notify.showToast('success', 'GoVideo', 'Recording stopped');
        this.isRecording = false;
      }
    } catch (error) {
      console.error('Error starting cloud recording', error);
      this.notify.showToast('error', 'GoVideo', 'Unable to start/stop recording', 0);
      this.isRecording = false;
    }
  }

  async takePhoto(event: any): Promise<void> {
    if (!this.zoomService.inspector) {
      throw new Error('Inspector not found');
    }
    const command: CommandEvent = {
      type: CommandEventType.IMAGE_CAPTURE_REQUESTED,
      data: {},
    }
    const commandResponse = await this.zoomService.sendCommand(command, this.zoomService.inspector.userId || 0);
    
    // this.messageService.add({ id: '', severity: 'error', summary: 'Capture Photo', detail: 'Unable capture photo. Ask the inspector to check allowed permissions.'});
  }

  endSession(event: any): void {
    this.confirmationService.confirm({
      message: 'This will also end the inspector\'s session. Are you sure you want to leave and end the session?',
      header: 'Leave Govideo Session?',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon:"none",
      rejectIcon:"none",
      // rejectButtonStyleClass:"p-button-text",
      closeOnEscape: true,
      blockScroll: true,
      dismissableMask: true,
      accept: async () => {
        try {
          this.cdr.detach();
          await this.sendSessionEndRequest();
          const user = this.zoomClient?.getCurrentUserInfo() || null;
          await this.zoomService.leave();
          this.zoomService.destroyClient();
          this.isSessionStarted = false;
          this.sessionEnded = true;
          this.cdr.reattach();
          this.notify.showToast('info', 'GoVideo', 'You have left the session');  
          console.log('Coordinator session ended');
          this.router.navigate(['/session-end']);
        } catch (error) {
          this.notify.showToast('error', 'GoVideo', 'Failed to end the session', 0);  
          throw new Error('Failed to end session');
        }
      },
      reject: () => {
          this.notify.showToast('info', 'GoVideo', 'You are still connected to the session', 3000);
      }
    });
  }

  async startInspectorVideo(participant?: Participant) {
    try {
      if (!this.zoomClient) {
        throw new Error('Zoom client not initialized');
      }
      let inspector = participant || undefined; 
      if (!inspector) {
        const participants = this.zoomClient.getAllUser();
        inspector = participants.find((p) => p.userIdentity === 'Inspector');
      }
      if (inspector && inspector.isVideoConnect && inspector.bVideoOn) {
        const stream = this.zoomClient.getMediaStream();
        if (!stream) {
          throw new Error('Media stream not found');
        }
  
        const inspectorVideo = this.inspectorVideo.nativeElement;
  
        if (!inspectorVideo) {
          throw new Error('Video elements not found');
        }

        const canvasWidth = inspectorVideo.width;
        const canvasHeight = inspectorVideo.height;
        const aspectRatio = inspectorVideo.width / inspectorVideo.height;
        const centerX =(canvasWidth - canvasWidth) / 2;
        const centerY = (canvasHeight - canvasHeight) / 2;

        this.cdr.detach();  
        await stream.renderVideo( inspectorVideo, inspector.userId, canvasWidth, canvasHeight, centerX, centerY, VideoQuality.Video_720P);  
        this.cdr.reattach();
        this.cdr.detectChanges();
        this.isInspectorConnected = true;
        console.log('Inspector video and audio started');
      }
    } catch (error) {
      this.isInspectorConnected = false;
      console.error('Error starting inspector video and audio', error);
    }
  }

  pause() {
    try {
      if (this.isRecording) {
        if (!this.cloudRecording) {
          console.info('Recording has not been started yet.');
          return;
        }
        if (!this.isPaused) {
          this.cloudRecording.pauseCloudRecording();
          this.isPaused = true;
        } else {
          this.cloudRecording.resumeCloudRecording();
          this.isPaused = false;
        }
      }
    } catch (error) {
      console.error('Error pausing/unpausing recording', error);
      this.isPaused = false;
    }
  }

  async selectMic(event: any): Promise<void> {
    try {
      await this.zoomService.selectMic(event.value);
    } catch (error) {
      console.error('Error selecting audio device', error);
    }
  }

  async selectSpeaker(event: any): Promise<void> {
    try {
      await this.zoomService.selectMic(event.value);
    } catch (error) {
      console.error('Error selecting audio device', error);
    }
  }

  async getCameraList() {
    if (!this.zoomService.inspector || !this.zoomService.coordinator) {
      throw new Error('Inspector or coordinator not found');
    }
    const commandEvent: CommandEvent = {
      type: CommandEventType.CAMERA_SWITCH_REQUESTED,
      data: null,
    }   
    const commandResponse = await this.zoomService.sendCommand(commandEvent, this.zoomService.inspector.userId);
    console.log(commandResponse);
  }

  async getInspectorCameraList(event: any) {
    if (!this.zoomService.inspector) {
      throw new Error('Get camera list: Inspector not found');
    }
    this.zoomService.inspectorCameraList = null;
    this.inspectorCameraList = null;
    const commandEvent: CommandEvent = {
      type: CommandEventType.CAMERA_GET_LIST_REQUESTED,
      data: null,
    }
    const commandResponse = await this.zoomService.sendCommand(commandEvent, this.zoomService.inspector.userId);
    const checkCameraListInterval = setInterval(async () => {
      if (!this.zoomService.inspectorCameraList) {
        return;
      }
      if (this.zoomService.inspectorCameraList.length > 0) {
        this.inspectorCameraList = this.zoomService.inspectorCameraList;
        this.selectedInspectorCamera = this.inspectorCameraList[0];
        clearInterval(checkCameraListInterval);
      }
    }, 1000);
  }

  async selectInspectorCamera(event: any) {
    if (!this.zoomService.inspector) {
      throw new Error('Select camera: Inspector not found');
    }
    const commandEvent: CommandEvent = {
      type: CommandEventType.CAMERA_SWITCH_REQUESTED,
      data: { cameraId: event.value },
    }
    await this.zoomService.sendCommand(commandEvent, this.zoomService.inspector.userId);
  
    this.inspectorCameraList = null;
  
    console.log('Selected camera for inspector', event.value);
  
  }

  async sendSessionEndRequest(): Promise<void> {
    const inspectorId = this.zoomService.inspector?.userId || 0;
    if (inspectorId) {
      const commandResponse = await this.zoomService.sendCommand({
        type: CommandEventType.SESSION_END_REQUESTED,
        data: {},
      }, inspectorId);
      console.log('Coordinator session end request sent', commandResponse);
    }
    return;
  }
}