import { Injectable, NgZone, OnDestroy, signal, WritableSignal, PLATFORM_ID, Inject} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private _orientation: WritableSignal<string> = signal(this.getCurrentOrientation());
  orientation$ = this._orientation.asReadonly();
  private subscriptions: Subscription[] = [];
  private isBrowser: boolean;

  constructor(private ngZone: NgZone, @Inject(PLATFORM_ID) platformId: Object) { 
    this.isBrowser = isPlatformBrowser(platformId);

    if (this.isMobile() && this.isBrowser) {
      this._orientation.set(this.getCurrentOrientation());

      this.ngZone.runOutsideAngular(() => {
        if (window.screen && window.screen.orientation) {
          const orientationChange$ = fromEvent<ScreenOrientation>(window.screen.orientation, 'change');
          const sub1 = orientationChange$.subscribe(() => {
            this.updateOrientation();
          });
          this.subscriptions.push(sub1);
        } else {
          const resize$ = fromEvent<Event>(window, 'resize');
          const sub2 = resize$.subscribe(() => {
            this.updateOrientation();
          });
          this.subscriptions.push(sub2);
        }
      });
    }
  }

  isApplePhone(): boolean {
    return navigator?.userAgent && navigator.userAgent.includes('iPhone') ? true : false;
  }

  isMobile(): boolean {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }

  async getVideoDevices() {
    try {
      const videoDevices = await  navigator.mediaDevices.enumerateDevices()
      .then(devices => devices.filter(device => device.kind === 'videoinput'));
      return videoDevices;
    } catch (error) {
      console.error('Error enumerating video input devices:', error);
      return [];
    }

  }

  private getCurrentOrientation(): string {
    if (window.screen && window.screen.orientation) {
      return window.screen.orientation.type;
    } else {
      return window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
    }
  }

  private updateOrientation() {
    this.ngZone.run(() => {
      this._orientation.set(this.getCurrentOrientation());
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  async listCameras() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');
      return videoDevices;
    } catch (error) {
      console.error('Error enumerating devices:', error);
      return [];
    }
  }
}
